"use strict";
import { TradeType } from "@uniswap/sdk-core";
import { wagmiConfig } from "components/Web3Provider/wagmiConfig";
import { clientToProvider } from "hooks/useEthersProvider";
import ms from "ms";
import { addTransaction, finalizeTransaction } from "state/transactions/reducer";
import {
  TransactionType
} from "state/transactions/types";
import { isPendingTx } from "state/transactions/utils";
import { call, cancel, fork, put, race, select, take } from "typed-redux-saga";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import {
  HandledTransactionInterrupt,
  TransactionStepFailedError,
  UnexpectedTransactionStateError
} from "uniswap/src/features/transactions/errors";
import { isUniswapX } from "uniswap/src/features/transactions/swap/utils/routing";
import { interruptTransactionFlow } from "uniswap/src/utils/saga";
import { isSameAddress } from "utilities/src/addresses";
import { percentFromFloat } from "utilities/src/format/percent";
import noop from "utilities/src/react/noop";
import { currencyId } from "utils/currencyId";
import { signTypedData } from "utils/signing";
import { getConnectorClient } from "wagmi/actions";
export function* handleSignatureStep({ setCurrentStep, step, ignoreInterrupt }) {
  const { throwIfInterrupted } = yield* watchForInterruption(ignoreInterrupt);
  setCurrentStep({ step, accepted: false });
  const signer = yield* call(getSigner);
  const signature = yield* call(signTypedData, signer, step.domain, step.types, step.values);
  yield* call(throwIfInterrupted);
  return signature;
}
export function* handleOnChainStep(params) {
  const { account, step, setCurrentStep, info, allowDuplicativeTx, ignoreInterrupt, onModification } = params;
  const { chainId } = step.txRequest;
  const signer = yield* call(getSigner);
  const duplicativeTx = yield* findDuplicativeTx(info, account, chainId, allowDuplicativeTx);
  if (duplicativeTx) {
    if (duplicativeTx.status === TransactionStatus.Confirmed) {
      return duplicativeTx.hash;
    } else {
      setCurrentStep({ step, accepted: true });
      return yield* handleOnChainConfirmation(params, duplicativeTx.hash);
    }
  }
  const { throwIfInterrupted } = yield* watchForInterruption(ignoreInterrupt);
  setCurrentStep({ step, accepted: false });
  const response = yield* call([signer, "sendTransaction"], step.txRequest);
  const { hash, nonce, data } = response;
  setCurrentStep({ step, accepted: true });
  yield* put(addTransaction({ from: account.address, info, hash, nonce, chainId }));
  if (step.txRequest.data !== data) {
    onModification?.(response);
  }
  yield* call(throwIfInterrupted);
  return yield* handleOnChainConfirmation(params, hash);
}
function* handleOnChainConfirmation(params, hash) {
  const { step, shouldWaitForConfirmation = true, ignoreInterrupt } = params;
  if (!shouldWaitForConfirmation) {
    return hash;
  }
  if (ignoreInterrupt) {
    yield* call(waitForTransaction, hash, step);
    return hash;
  }
  const { interrupt } = yield* race({
    transactionFinished: call(waitForTransaction, hash, step),
    interrupt: take(interruptTransactionFlow.type)
  });
  if (interrupt) {
    throw new HandledTransactionInterrupt("Transaction flow was interrupted");
  }
  return hash;
}
export function* handleApprovalTransactionStep(params) {
  const { step } = params;
  const info = getApprovalTransactionInfo(step);
  return yield* call(handleOnChainStep, { ...params, info });
}
function getApprovalTransactionInfo(approvalStep) {
  return {
    type: TransactionType.APPROVAL,
    tokenAddress: approvalStep.token.address,
    spender: approvalStep.spender,
    amount: approvalStep.amount
  };
}
function isRecentTx(tx) {
  const currentTime = Date.now();
  const failed = tx.status === TransactionStatus.Failed;
  return !failed && currentTime - tx.addedTime < ms("30s");
}
function* findDuplicativeTx(info, account, chainId, allowDuplicativeTx) {
  if (allowDuplicativeTx) {
    return void 0;
  }
  const transactionMap = (yield* select((state) => state.localWebTransactions[chainId])) ?? {};
  const transactionsForAccount = Object.values(transactionMap).filter((tx) => isSameAddress(tx.from, account.address));
  return transactionsForAccount.find(
    (tx) => (isPendingTx(tx) || isRecentTx(tx)) && JSON.stringify(tx.info) === JSON.stringify(info)
  );
}
function* watchForInterruption(ignoreInterrupt = false) {
  if (ignoreInterrupt) {
    return { throwIfInterrupted: noop };
  }
  let wasInterrupted = false;
  const watchForInterruptionTask = yield* fork(function* () {
    yield* take(interruptTransactionFlow.type);
    wasInterrupted = true;
  });
  function* throwIfInterrupted() {
    if (wasInterrupted) {
      throw new HandledTransactionInterrupt("Transaction flow was interrupted");
    }
    yield* cancel(watchForInterruptionTask);
  }
  return { throwIfInterrupted };
}
function* waitForTransaction(hash, step) {
  while (true) {
    const { payload } = yield* take(finalizeTransaction.type);
    if (payload.hash === hash) {
      if (payload.status === TransactionStatus.Confirmed) {
        return;
      } else {
        throw new TransactionStepFailedError({ message: `${step.type} failed during swap`, step });
      }
    }
  }
}
async function getProvider() {
  const client = await getConnectorClient(wagmiConfig);
  const provider = clientToProvider(client);
  if (!provider) {
    throw new UnexpectedTransactionStateError(`Failed to get provider during transaction flow`);
  }
  return provider;
}
async function getSigner() {
  return (await getProvider()).getSigner();
}
export function getSwapTransactionInfo(trade) {
  const slippage = percentFromFloat(trade.slippageTolerance);
  return {
    type: TransactionType.SWAP,
    inputCurrencyId: currencyId(trade.inputAmount.currency),
    outputCurrencyId: currencyId(trade.outputAmount.currency),
    isUniswapXOrder: isUniswapX(trade),
    ...trade.tradeType === TradeType.EXACT_INPUT ? {
      tradeType: TradeType.EXACT_INPUT,
      inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
      expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
      minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(slippage).quotient.toString()
    } : {
      tradeType: TradeType.EXACT_OUTPUT,
      maximumInputCurrencyAmountRaw: trade.maximumAmountIn(slippage).quotient.toString(),
      outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
      expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString()
    }
  };
}
