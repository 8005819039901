"use strict";
import { useTokenBalancesQuery } from "graphql/data/apollo/AdaptiveTokenBalancesProvider";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import { GQL_MAINNET_CHAINS_MUTABLE } from "uniswap/src/constants/chains";
import {
  useQuickTokenBalancesWebQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { currencyKeyFromGraphQL } from "utils/currencyKey";
export function useTokenBalances({ cacheOnly } = {}) {
  const account = useAccount();
  const quickQueryResult = useQuickTokenBalancesWebQuery({
    variables: {
      ownerAddress: account.address ?? "",
      chains: GQL_MAINNET_CHAINS_MUTABLE
    },
    skip: !account.address,
    fetchPolicy: "cache-first"
  });
  const fullQueryResult = useTokenBalancesQuery({ cacheOnly });
  const { data, loading } = fullQueryResult.data ? fullQueryResult : quickQueryResult;
  return useMemo(() => {
    const balanceList = data?.portfolios?.[0]?.tokenBalances ?? [];
    const balanceMap = balanceList?.reduce((balanceMap2, tokenBalance) => {
      if (!tokenBalance?.token) {
        return balanceMap2;
      }
      const key = currencyKeyFromGraphQL({
        address: tokenBalance.token.address,
        chain: tokenBalance.token.chain,
        standard: tokenBalance.token.standard
      });
      const usdValue = tokenBalance.denominatedValue?.value ?? 0;
      const balance = tokenBalance.quantity ?? 0;
      balanceMap2[key] = { usdValue, balance };
      return balanceMap2;
    }, {}) ?? {};
    return { balanceMap, balanceList, loading };
  }, [data?.portfolios, loading]);
}
