"use strict";
import HolidayUniIcon from "components/Logo/HolidayUniIcon";
import styled from "lib/styled-components";
function Logo({ onClick }) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="107"
    height="18"
    viewBox="0 0 107 18"
    fill="none"
    onClick={onClick}
    cursor="pointer"
  >
    <path
      d="M2.74463 17.2794H0V12.8408C0 11.3191 1.22881 10.0854 2.74463 10.0854V7.68361C1.22881 7.68361 0 6.44998 0 4.92822V0.720629H2.74463V7.68361H10.4784V0.720629H13.2231V17.2794H10.4784V10.0854H2.74463V17.2794Z"
      fill="white"
    />
    <path
      d="M18.8291 13.1916L17.2823 17.2794H14.4447L20.6898 0.720629H23.9578L30.1681 17.2794H27.2374L25.6674 13.1916H18.8291ZM24.8998 10.8707L22.2366 3.65363L19.5966 10.8707H24.8998Z"
      fill="white"
    />
    <path
      d="M30.3779 10.1894V0.720629H33.1109V10.3279C33.1109 13.4457 34.3786 15.0046 36.9255 15.0046C39.5654 15.0046 40.7866 13.4341 40.7866 10.3279V0.720629H43.5196V10.1894C43.5196 14.9122 41.2518 17.4179 36.9255 17.4179C32.6341 17.4179 30.3779 14.9122 30.3779 10.1894Z"
      fill="white"
    />
    <path
      d="M45.3343 11.8406L48.0905 11.8868C48.1603 12.8683 48.5557 13.6535 49.2768 14.2424C49.9978 14.8314 50.9398 15.1316 52.0912 15.1316C53.103 15.1316 53.9287 14.9122 54.5567 14.4734C55.1847 14.0461 55.4987 13.4457 55.4987 12.672C55.4987 12.1755 55.3359 11.7598 55.1033 11.4711C54.8707 11.1824 54.3706 10.9053 53.9636 10.7321C53.7542 10.6513 53.4751 10.5589 53.1262 10.478C52.7773 10.3972 52.4633 10.3164 52.1959 10.2586C51.9284 10.2009 51.5678 10.1432 51.1143 10.0623C49.8699 9.84295 49.0326 9.64664 48.044 9.17321C47.5439 8.93071 47.1369 8.65358 46.8229 8.34181C46.1833 7.71825 45.7413 6.71364 45.7413 5.4319C45.7413 3.9885 46.2879 2.81068 47.3811 1.92154C48.4627 1.0324 49.8932 0.582062 51.6609 0.582062C53.5565 0.582062 55.0684 1.04395 56.1965 1.97928C57.3246 2.9146 57.9177 4.15016 57.9759 5.68594H55.2429C55.1382 4.84299 54.766 4.16171 54.1264 3.64208C53.4868 3.134 52.661 2.87996 51.6609 2.87996C49.7187 2.87996 48.4743 3.72291 48.4743 5.24715C48.4743 5.66285 48.5557 6.00926 48.7883 6.2864C49.0209 6.57508 49.2303 6.78293 49.6722 6.96768C50.1141 7.15244 50.4165 7.26791 51.0445 7.40648C51.3585 7.47576 51.626 7.5335 51.8702 7.57969C52.1145 7.62588 52.4285 7.68361 52.8122 7.7529C53.4402 7.86837 53.9868 7.98384 54.452 8.11086C54.9172 8.23788 55.4057 8.41109 55.9174 8.65358C56.4175 8.89607 56.8361 9.17321 57.1501 9.48498C57.7898 10.1201 58.255 11.1247 58.2433 12.3833C58.2433 13.8729 57.6735 15.0854 56.5338 16.0207C55.3824 16.956 53.8938 17.4179 52.0679 17.4179C50.0327 17.4179 48.4045 16.9099 47.2067 15.8822C46.0088 14.866 45.3808 13.515 45.3343 11.8406Z"
      fill="white"
    />
    <path d="M63.6675 3.134H58.5271V0.720629H71.5525V3.134H66.4121V17.2794H63.6675V3.134Z" fill="white" />
    <rect
      x="74.7491"
      y="0.827946"
      width="32.0052"
      height="16.139"
      rx="4.67181"
      stroke="white"
      strokeWidth="0.491769"
    />
    <path
      d="M96.2346 4.02444L98.5664 7.83623H98.6426L100.974 4.02444H102.678L99.6419 8.89743L102.697 13.7704H100.984L98.6426 10.011H98.5664L96.2251 13.7704H94.512L97.6194 8.89743L94.531 4.02444H96.2346Z"
      fill="white"
    />
    <path
      d="M87.8071 13.7704V4.02444H93.9174V5.29028H89.2776V8.25976H93.5986V9.52083H89.2776V12.5046H93.9745V13.7704H87.8071Z"
      fill="white"
    />
    <path
      d="M81.9612 13.7704H78.8062V4.02444H82.0612C83.0161 4.02444 83.8362 4.21955 84.5214 4.60977C85.2067 4.99682 85.7318 5.5536 86.0966 6.28011C86.4646 7.00344 86.6486 7.87112 86.6486 8.88316C86.6486 9.89836 86.463 10.7708 86.0918 11.5005C85.7238 12.2302 85.1908 12.7917 84.4929 13.1851C83.7949 13.5753 82.951 13.7704 81.9612 13.7704ZM80.2766 12.4856H81.8803C82.6227 12.4856 83.2397 12.346 83.7315 12.0668C84.2232 11.7844 84.5912 11.3768 84.8355 10.8438C85.0798 10.3076 85.2019 9.65408 85.2019 8.88316C85.2019 8.11858 85.0798 7.4698 84.8355 6.93682C84.5944 6.40383 84.2343 5.99934 83.7553 5.72333C83.2762 5.44732 82.6814 5.30931 81.9707 5.30931H80.2766V12.4856Z"
      fill="white"
    />
  </svg>;
}
const Container = styled.div`
  position: relative;
  cursor: ${({ clickable }) => clickable ? "pointer" : "auto"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NavIcon = ({ clickable, onClick, ...props }) => <Container clickable={clickable}>{HolidayUniIcon(props) !== null ? <HolidayUniIcon {...props} /> : <Logo onClick={onClick} />}</Container>;
