"use strict";
import { CurrencyAmount, Token, V3_CORE_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import IUniswapV3PoolStateJSON from "@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json";
import { Pool, Position, computePoolAddress } from "@uniswap/v3-sdk";
import {
  useCachedPositions,
  useGetCachedTokens,
  usePoolAddressCache
} from "components/AccountDrawer/MiniPortfolio/Pools/cache";
import { DEFAULT_GAS_LIMIT } from "components/AccountDrawer/MiniPortfolio/Pools/getTokensAsync";
import {
  useInterfaceMulticallContracts,
  usePoolPriceMap,
  useV3ManagerContracts
} from "components/AccountDrawer/MiniPortfolio/Pools/hooks";
import { PRODUCTION_CHAIN_IDS } from "constants/chains";
import { BigNumber } from "ethers/lib/ethers";
import { Interface } from "ethers/lib/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { logger } from "utilities/src/logger/logger";
import { DEFAULT_ERC20_DECIMALS } from "utilities/src/tokens/constants";
import { currencyKey } from "utils/currencyKey";
function createPositionInfo(owner, chainId, details, slot0, tokenA, tokenB) {
  const pool = new Pool(tokenA, tokenB, details.fee, slot0.sqrtPriceX96.toString(), 0, slot0.tick);
  const position = new Position({
    pool,
    liquidity: details.liquidity.toString(),
    tickLower: details.tickLower,
    tickUpper: details.tickUpper
  });
  const inRange = slot0.tick >= details.tickLower && slot0.tick < details.tickUpper;
  const closed = details.liquidity.eq(0);
  return { owner, chainId, pool, position, details, inRange, closed };
}
const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
export default function useMultiChainPositions(account, chains = PRODUCTION_CHAIN_IDS) {
  const pms = useV3ManagerContracts(chains);
  const multicalls = useInterfaceMulticallContracts(chains);
  const getTokens = useGetCachedTokens(chains);
  const poolAddressCache = usePoolAddressCache();
  const [cachedPositions, setPositions] = useCachedPositions(account);
  const positions = cachedPositions?.result;
  const positionsFetching = useRef(false);
  const positionsLoading = !cachedPositions?.result && positionsFetching.current;
  const [feeMap, setFeeMap] = useState({});
  const { priceMap, pricesLoading } = usePoolPriceMap(positions);
  const fetchPositionFees = useCallback(
    async (pm, positionIds, chainId) => {
      const callData = positionIds.map(
        (id) => pm.interface.encodeFunctionData("collect", [
          { tokenId: id, recipient: account, amount0Max: MAX_UINT128, amount1Max: MAX_UINT128 }
        ])
      );
      const fees = (await pm.callStatic.multicall(callData)).reduce(
        (acc, feeBytes, index) => {
          const key = chainId.toString() + positionIds[index];
          acc[key] = pm.interface.decodeFunctionResult("collect", feeBytes);
          return acc;
        },
        {}
      );
      setFeeMap((prev) => ({ ...prev, ...fees }));
    },
    [account]
  );
  const fetchPositionIds = useCallback(
    async (pm, balance) => {
      const callData = Array.from(
        { length: balance.toNumber() },
        (_, i) => pm.interface.encodeFunctionData("tokenOfOwnerByIndex", [account, i])
      );
      return (await pm.callStatic.multicall(callData)).map((idByte) => BigNumber.from(idByte));
    },
    [account]
  );
  const fetchPositionDetails = useCallback(async (pm, positionIds) => {
    const callData = positionIds.map((id) => pm.interface.encodeFunctionData("positions", [id]));
    return (await pm.callStatic.multicall(callData)).map(
      (positionBytes, index) => ({
        ...pm.interface.decodeFunctionResult("positions", positionBytes),
        tokenId: positionIds[index]
      })
    );
  }, []);
  const fetchPositionInfo = useCallback(
    async (positionDetails, chainId, multicall) => {
      const poolInterface = new Interface(IUniswapV3PoolStateJSON.abi);
      const tokens = await getTokens(
        positionDetails.flatMap((details) => [details.token0, details.token1]),
        chainId
      );
      const calls = [];
      const poolPairs = [];
      positionDetails.forEach((details) => {
        const tokenA = tokens[details.token0] ?? new Token(chainId, details.token0, DEFAULT_ERC20_DECIMALS);
        const tokenB = tokens[details.token1] ?? new Token(chainId, details.token1, DEFAULT_ERC20_DECIMALS);
        let poolAddress = poolAddressCache.get(details, chainId);
        if (!poolAddress) {
          const factoryAddress = V3_CORE_FACTORY_ADDRESSES[chainId];
          poolAddress = computePoolAddress({
            factoryAddress,
            tokenA,
            tokenB,
            fee: details.fee,
            chainId: UNIVERSE_CHAIN_INFO[chainId].sdkId
          });
          poolAddressCache.set(details, chainId, poolAddress);
        }
        poolPairs.push([tokenA, tokenB]);
        calls.push({
          target: poolAddress,
          callData: poolInterface.encodeFunctionData("slot0"),
          gasLimit: DEFAULT_GAS_LIMIT
        });
      }, []);
      return (await multicall.callStatic.multicall(calls)).returnData.reduce((acc, result, i) => {
        if (result.success) {
          const slot0 = poolInterface.decodeFunctionResult("slot0", result.returnData);
          acc.push(createPositionInfo(account, chainId, positionDetails[i], slot0, ...poolPairs[i]));
        } else {
          logger.debug("useMultiChainPositions", "fetchPositionInfo", "slot0 fetch errored", result);
        }
        return acc;
      }, []);
    },
    [account, poolAddressCache, getTokens]
  );
  const fetchPositionsForChain = useCallback(
    async (chainId) => {
      if (!account || account.length === 0) {
        return [];
      }
      try {
        const pm = pms[chainId];
        const multicall = multicalls[chainId];
        const balance = await pm?.balanceOf(account);
        if (!pm || !multicall || balance.lt(1)) {
          return [];
        }
        const positionIds = await fetchPositionIds(pm, balance);
        fetchPositionFees(pm, positionIds, chainId);
        const postionDetails = await fetchPositionDetails(pm, positionIds);
        return fetchPositionInfo(postionDetails, chainId, multicall);
      } catch (error) {
        const wrappedError = new Error("Failed to fetch positions for chain", { cause: error });
        logger.debug("useMultiChainPositions", "fetchPositionsForChain", wrappedError.message, {
          error: wrappedError,
          chainId
        });
        return [];
      }
    },
    [account, fetchPositionDetails, fetchPositionFees, fetchPositionIds, fetchPositionInfo, pms, multicalls]
  );
  const fetchAllPositions = useCallback(async () => {
    positionsFetching.current = true;
    const positions2 = (await Promise.all(chains.map(fetchPositionsForChain))).flat();
    positionsFetching.current = false;
    setPositions(positions2);
  }, [chains, fetchPositionsForChain, setPositions]);
  useEffect(() => {
    if (positionsFetching.current || cachedPositions?.stale === false) {
      return void 0;
    } else if (document.hasFocus()) {
      fetchAllPositions();
    } else {
      const onFocus = () => {
        fetchAllPositions();
        window.removeEventListener("focus", onFocus);
      };
      window.addEventListener("focus", onFocus);
      return () => {
        window.removeEventListener("focus", onFocus);
      };
    }
    return void 0;
  }, [fetchAllPositions, positionsFetching, cachedPositions?.stale]);
  const positionsWithFeesAndPrices = useMemo(
    () => positions?.map((position) => {
      const key = position.chainId.toString() + position.details.tokenId;
      const fees = feeMap[key] ? [
        // We parse away from SDK/ethers types so fees can be multiplied by primitive number prices
        parseFloat(CurrencyAmount.fromRawAmount(position.pool.token0, feeMap[key]?.[0].toString()).toExact()),
        parseFloat(CurrencyAmount.fromRawAmount(position.pool.token1, feeMap[key]?.[1].toString()).toExact())
      ] : void 0;
      const prices = [priceMap[currencyKey(position.pool.token0)], priceMap[currencyKey(position.pool.token1)]];
      return { ...position, fees, prices };
    }),
    [feeMap, positions, priceMap]
  );
  return { positions: positionsWithFeesAndPrices, loading: pricesLoading || positionsLoading };
}
