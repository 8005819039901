"use strict";
import AuthenticatedHeader from "components/AccountDrawer/AuthenticatedHeader";
import LanguageMenu from "components/AccountDrawer/LanguageMenu";
import LocalCurrencyMenu from "components/AccountDrawer/LocalCurrencyMenu";
import { LimitsMenu } from "components/AccountDrawer/MiniPortfolio/Limits/LimitsMenu";
import { UniExtensionPoolsMenu } from "components/AccountDrawer/MiniPortfolio/Pools/UniExtensionPoolsMenu";
import SettingsMenu from "components/AccountDrawer/SettingsMenu";
import Column from "components/deprecated/Column";
import WalletModal from "components/WalletModal";
import { useAccount } from "hooks/useAccount";
import { atom, useAtom } from "jotai";
import styled from "lib/styled-components";
import { useCallback, useEffect, useMemo } from "react";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
const DefaultMenuWrap = styled(Column)`
  width: 100%;
  height: 100%;
`;
export var MenuState = /* @__PURE__ */ ((MenuState2) => {
  MenuState2["DEFAULT"] = "default";
  MenuState2["SETTINGS"] = "settings";
  MenuState2["LANGUAGE_SETTINGS"] = "language_settings";
  MenuState2["LOCAL_CURRENCY_SETTINGS"] = "local_currency_settings";
  MenuState2["LIMITS"] = "limits";
  MenuState2["POOLS"] = "pools";
  return MenuState2;
})(MenuState || {});
export const miniPortfolioMenuStateAtom = atom("default" /* DEFAULT */);
function DefaultMenu({ drawerOpen }) {
  const account = useAccount();
  const [menu, setMenu] = useAtom(miniPortfolioMenuStateAtom);
  const openSettings = useCallback(() => setMenu("settings" /* SETTINGS */), [setMenu]);
  const closeSettings = useCallback(() => setMenu("default" /* DEFAULT */), [setMenu]);
  const openLanguageSettings = useCallback(() => setMenu("language_settings" /* LANGUAGE_SETTINGS */), [setMenu]);
  const openLocalCurrencySettings = useCallback(() => setMenu("local_currency_settings" /* LOCAL_CURRENCY_SETTINGS */), [setMenu]);
  const closeLimitsMenu = useCallback(() => setMenu("default" /* DEFAULT */), [setMenu]);
  useEffect(() => {
    if (!drawerOpen && menu !== "default" /* DEFAULT */) {
      const timer = setTimeout(() => {
        closeSettings();
      }, 250);
      return () => clearTimeout(timer);
    }
    return void 0;
  }, [drawerOpen, menu, closeSettings]);
  useEffect(() => {
    if (menu === "default" /* DEFAULT */) {
      return;
    }
    sendAnalyticsEvent(InterfaceEventNameLocal.PortfolioMenuOpened, { name: menu });
  }, [menu]);
  const SubMenu = useMemo(() => {
    switch (menu) {
      case "default" /* DEFAULT */:
        return account.address ? <AuthenticatedHeader account={account.address} openSettings={openSettings} /> : <WalletModal />;
      case "settings" /* SETTINGS */:
        return <SettingsMenu
          onClose={closeSettings}
          openLanguageSettings={openLanguageSettings}
          openLocalCurrencySettings={openLocalCurrencySettings}
        />;
      case "language_settings" /* LANGUAGE_SETTINGS */:
        return <LanguageMenu onClose={openSettings} />;
      case "local_currency_settings" /* LOCAL_CURRENCY_SETTINGS */:
        return <LocalCurrencyMenu onClose={openSettings} />;
      case "limits" /* LIMITS */:
        return account.address ? <LimitsMenu onClose={closeLimitsMenu} account={account.address} /> : null;
      case "pools" /* POOLS */:
        return account.address ? <UniExtensionPoolsMenu account={account.address} onClose={closeLimitsMenu} /> : null;
    }
  }, [
    account.address,
    closeLimitsMenu,
    closeSettings,
    menu,
    openLanguageSettings,
    openLocalCurrencySettings,
    openSettings
  ]);
  return <DefaultMenuWrap>{SubMenu}</DefaultMenuWrap>;
}
export default DefaultMenu;
