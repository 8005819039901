"use strict";
import { formatEther as ethersFormatEther } from "@ethersproject/units";
import { getCurrencySymbolDisplayType } from "constants/localCurrencies";
import { useActiveLocalCurrency } from "hooks/useActiveLocalCurrency";
import { useActiveLocale } from "hooks/useActiveLocale";
import usePrevious from "hooks/usePrevious";
import { useCallback, useMemo } from "react";
import { Bound } from "state/mint/v3/actions";
import { DEFAULT_LOCAL_CURRENCY } from "uniswap/src/features/fiatCurrency/constants";
import { useLocalizationContext } from "uniswap/src/features/language/LocalizationContext";
import { DEFAULT_LOCALE } from "uniswap/src/features/language/constants";
const FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN = {
  notation: "standard",
  maximumFractionDigits: 5,
  minimumFractionDigits: 2
};
const FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN_NO_COMMAS = {
  notation: "standard",
  maximumFractionDigits: 5,
  minimumFractionDigits: 2,
  useGrouping: false
};
const NO_DECIMALS = {
  notation: "standard",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
};
const NO_DECIMALS_CURRENCY = {
  notation: "standard",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  currency: "USD",
  style: "currency"
};
const THREE_DECIMALS_NO_TRAILING_ZEROS = {
  notation: "standard",
  maximumFractionDigits: 3,
  minimumFractionDigits: 0
};
const THREE_DECIMALS = {
  notation: "standard",
  maximumFractionDigits: 3,
  minimumFractionDigits: 3
};
const THREE_DECIMALS_CURRENCY = {
  notation: "standard",
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
  currency: "USD",
  style: "currency"
};
const THREE_DECIMALS_NO_TRAILING_ZEROS_CURRENCY = {
  ...THREE_DECIMALS_NO_TRAILING_ZEROS,
  currency: "USD",
  style: "currency"
};
const TWO_DECIMALS_NO_TRAILING_ZEROS = {
  notation: "standard",
  maximumFractionDigits: 2
};
const TWO_DECIMALS_NO_TRAILING_ZEROS_CURRENCY = {
  ...TWO_DECIMALS_NO_TRAILING_ZEROS,
  currency: "USD",
  style: "currency"
};
const TWO_DECIMALS = {
  notation: "standard",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
};
const TWO_DECIMALS_CURRENCY = {
  notation: "standard",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currency: "USD",
  style: "currency"
};
const EIGHT_DECIMALS_CURRENCY = {
  notation: "standard",
  maximumFractionDigits: 8,
  minimumFractionDigits: 2,
  currency: "USD",
  style: "currency"
};
const SHORTHAND_TWO_DECIMALS = {
  notation: "compact",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};
const SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS = {
  notation: "compact",
  maximumFractionDigits: 2
};
const SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS_CURRENCY = {
  ...SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS,
  currency: "USD",
  style: "currency"
};
const SHORTHAND_ONE_DECIMAL = {
  notation: "compact",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
};
const SHORTHAND_CURRENCY_TWO_DECIMALS = {
  notation: "compact",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: "USD",
  style: "currency"
};
const SHORTHAND_CURRENCY_ONE_DECIMAL = {
  notation: "compact",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  currency: "USD",
  style: "currency"
};
const SIX_SIG_FIGS_TWO_DECIMALS = {
  notation: "standard",
  maximumSignificantDigits: 6,
  minimumSignificantDigits: 3,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
};
const SIX_SIG_FIGS_NO_COMMAS = {
  notation: "standard",
  maximumSignificantDigits: 6,
  useGrouping: false
};
const SIX_SIG_FIGS_TWO_DECIMALS_NO_COMMAS = {
  notation: "standard",
  maximumSignificantDigits: 6,
  minimumSignificantDigits: 3,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: false
};
const ONE_SIG_FIG_CURRENCY = {
  notation: "standard",
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 1,
  currency: "USD",
  style: "currency"
};
const THREE_SIG_FIGS_CURRENCY = {
  notation: "standard",
  minimumSignificantDigits: 3,
  maximumSignificantDigits: 3,
  currency: "USD",
  style: "currency"
};
const SEVEN_SIG_FIGS__SCI_NOTATION_CURRENCY = {
  notation: "scientific",
  minimumSignificantDigits: 7,
  maximumSignificantDigits: 7,
  currency: "USD",
  style: "currency"
};
const tokenNonTxFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  { upperBound: 1e-3, hardCodedInput: { input: 1e-3, prefix: "<" }, formatterOptions: THREE_DECIMALS },
  { upperBound: 1, formatterOptions: THREE_DECIMALS },
  { upperBound: 1e6, formatterOptions: TWO_DECIMALS },
  { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS },
  {
    upperBound: Infinity,
    hardCodedInput: { input: 999e12, prefix: ">" },
    formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS
  }
];
const tokenQuantityStatsFormatter = [
  // if token stat value is 0, we probably don't have the data for it, so show '-' as a placeholder
  { exact: 0, hardCodedInput: { hardcodedOutput: "-" }, formatterOptions: NO_DECIMALS },
  { upperBound: 0.01, hardCodedInput: { input: 0.01, prefix: "<" }, formatterOptions: TWO_DECIMALS },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS },
  { upperBound: Infinity, formatterOptions: SHORTHAND_ONE_DECIMAL }
];
const tokenTxFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  {
    upperBound: 1e-5,
    hardCodedInput: { input: 1e-5, prefix: "<" },
    formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN
  },
  { upperBound: 1, formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN },
  { upperBound: 1e4, formatterOptions: SIX_SIG_FIGS_TWO_DECIMALS },
  { upperBound: Infinity, formatterOptions: TWO_DECIMALS }
];
const swapTradeAmountFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  { upperBound: 0.1, formatterOptions: SIX_SIG_FIGS_NO_COMMAS },
  { upperBound: 1, formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN_NO_COMMAS },
  { upperBound: Infinity, formatterOptions: SIX_SIG_FIGS_TWO_DECIMALS_NO_COMMAS }
];
const swapDetailsAmountFormatter = [{ upperBound: Infinity, formatterOptions: SIX_SIG_FIGS_NO_COMMAS }];
const swapPriceFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  {
    upperBound: 1e-5,
    hardCodedInput: { input: 1e-5, prefix: "<" },
    formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN
  },
  ...swapTradeAmountFormatter
];
const fiatTokenDetailsFormatter = [
  { exact: 0, formatterOptions: TWO_DECIMALS_CURRENCY },
  {
    upperBound: 1e-8,
    hardCodedInput: { input: 1e-8, prefix: "<" },
    formatterOptions: ONE_SIG_FIG_CURRENCY
  },
  { upperBound: 0.1, formatterOptions: THREE_SIG_FIGS_CURRENCY },
  { upperBound: 1.05, formatterOptions: THREE_DECIMALS_CURRENCY },
  { upperBound: 1e6, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: SHORTHAND_CURRENCY_TWO_DECIMALS }
];
const chartVolumePriceScale = [
  {
    upperBound: 1e-3,
    hardCodedInput: { input: 1e-3, prefix: "<" },
    formatterOptions: ONE_SIG_FIG_CURRENCY
  },
  { upperBound: 2, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: 1e3, formatterOptions: NO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: SHORTHAND_CURRENCY_ONE_DECIMAL }
];
const chartFiatValueFormatter = [
  // if token stat value is 0, we probably don't have the data for it, so show '-' as a placeholder
  { exact: 0, hardCodedInput: { hardcodedOutput: "-" }, formatterOptions: ONE_SIG_FIG_CURRENCY },
  { upperBound: 1.05, formatterOptions: EIGHT_DECIMALS_CURRENCY },
  { upperBound: 1e6, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: SHORTHAND_CURRENCY_TWO_DECIMALS }
];
const fiatTokenPricesFormatter = [
  { exact: 0, formatterOptions: TWO_DECIMALS_CURRENCY },
  {
    upperBound: 1e-8,
    hardCodedInput: { input: 1e-8, prefix: "<" },
    formatterOptions: ONE_SIG_FIG_CURRENCY
  },
  { upperBound: 1, formatterOptions: THREE_SIG_FIGS_CURRENCY },
  { upperBound: 1e6, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: 1e16, formatterOptions: SHORTHAND_CURRENCY_TWO_DECIMALS },
  { upperBound: Infinity, formatterOptions: SEVEN_SIG_FIGS__SCI_NOTATION_CURRENCY }
];
const fiatTokenStatsFormatter = [
  // if token stat value is 0, we probably don't have the data for it, so show '-' as a placeholder
  { exact: 0, hardCodedInput: { hardcodedOutput: "-" }, formatterOptions: ONE_SIG_FIG_CURRENCY },
  { upperBound: 0.01, hardCodedInput: { input: 0.01, prefix: "<" }, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: SHORTHAND_CURRENCY_ONE_DECIMAL }
];
const fiatGasPriceFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS_CURRENCY },
  { upperBound: 0.01, hardCodedInput: { input: 0.01, prefix: "<" }, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: 1e6, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: SHORTHAND_CURRENCY_TWO_DECIMALS }
];
const fiatTokenQuantityFormatter = [
  { exact: 0, formatterOptions: TWO_DECIMALS_CURRENCY },
  ...fiatGasPriceFormatter
];
const portfolioBalanceFormatter = [
  { exact: 0, formatterOptions: TWO_DECIMALS_CURRENCY },
  { upperBound: Infinity, formatterOptions: TWO_DECIMALS_CURRENCY }
];
const ntfTokenFloorPriceFormatterTrailingZeros = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  { upperBound: 1e-3, hardCodedInput: { input: 1e-3, prefix: "<" }, formatterOptions: THREE_DECIMALS },
  { upperBound: 1, formatterOptions: THREE_DECIMALS },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS },
  { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS },
  {
    upperBound: Infinity,
    hardCodedInput: { input: 999e12, prefix: ">" },
    formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS
  }
];
const ntfTokenFloorPriceFormatter = [
  { exact: 0, formatterOptions: NO_DECIMALS },
  { upperBound: 1e-3, hardCodedInput: { input: 1e-3, prefix: "<" }, formatterOptions: THREE_DECIMALS },
  { upperBound: 1, formatterOptions: THREE_DECIMALS_NO_TRAILING_ZEROS },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS_NO_TRAILING_ZEROS },
  { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS },
  {
    upperBound: Infinity,
    hardCodedInput: { input: 999e12, prefix: ">" },
    formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS
  }
];
const ntfCollectionStatsFormatter = [
  { upperBound: 1e3, formatterOptions: NO_DECIMALS },
  { upperBound: Infinity, formatterOptions: SHORTHAND_ONE_DECIMAL }
];
const nftTokenFormatter = [
  { exact: 0, hardCodedInput: { hardcodedOutput: "-" }, formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN },
  {
    upperBound: 1e-4,
    hardCodedInput: { input: 1e-4, prefix: "<" },
    formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN
  },
  { upperBound: 1, formatterOptions: THREE_DECIMALS },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS_NO_TRAILING_ZEROS },
  { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS },
  {
    upperBound: Infinity,
    hardCodedInput: { input: 999e12, prefix: ">" },
    formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS
  }
];
const fiatNftTokenFormatter = [
  { exact: 0, hardCodedInput: { hardcodedOutput: "-" }, formatterOptions: NO_DECIMALS },
  {
    upperBound: 1e-4,
    hardCodedInput: { input: 1e-4, prefix: "<" },
    formatterOptions: ONE_SIG_FIG_CURRENCY
  },
  { upperBound: 1, formatterOptions: THREE_DECIMALS_NO_TRAILING_ZEROS_CURRENCY },
  { upperBound: 1e3, formatterOptions: TWO_DECIMALS_NO_TRAILING_ZEROS_CURRENCY },
  { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS_CURRENCY },
  {
    upperBound: Infinity,
    hardCodedInput: { input: 999e12, prefix: ">" },
    formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS_CURRENCY
  }
];
const wholeNumberFormatter = [{ upperBound: Infinity, formatterOptions: NO_DECIMALS }];
export var NumberType = /* @__PURE__ */ ((NumberType2) => {
  NumberType2["TokenNonTx"] = "token-non-tx";
  NumberType2["TokenQuantityStats"] = "token-quantity-stats";
  NumberType2["TokenTx"] = "token-tx";
  NumberType2["SwapPrice"] = "swap-price";
  NumberType2["SwapTradeAmount"] = "swap-trade-amount";
  NumberType2["SwapDetailsAmount"] = "swap-details-amount";
  NumberType2["ChartFiatValue"] = "chart-fiat-value";
  NumberType2["ChartVolumePriceScale"] = "chart-volume-price-scale";
  NumberType2["FiatTokenDetails"] = "fiat-token-details";
  NumberType2["FiatTokenPrice"] = "fiat-token-price";
  NumberType2["FiatTokenStats"] = "fiat-token-stats";
  NumberType2["FiatTokenQuantity"] = "fiat-token-quantity";
  NumberType2["FiatGasPrice"] = "fiat-gas-price";
  NumberType2["PortfolioBalance"] = "portfolio-balance";
  NumberType2["NFTTokenFloorPrice"] = "nft-token-floor-price";
  NumberType2["NFTCollectionStats"] = "nft-collection-stats";
  NumberType2["NFTTokenFloorPriceTrailingZeros"] = "nft-token-floor-price-trailing-zeros";
  NumberType2["NFTToken"] = "nft-token";
  NumberType2["FiatNFTToken"] = "fiat-nft-token";
  NumberType2["WholeNumber"] = "whole-number";
  return NumberType2;
})(NumberType || {});
const TYPE_TO_FORMATTER_RULES = {
  ["token-non-tx" /* TokenNonTx */]: tokenNonTxFormatter,
  ["token-quantity-stats" /* TokenQuantityStats */]: tokenQuantityStatsFormatter,
  ["token-tx" /* TokenTx */]: tokenTxFormatter,
  ["swap-price" /* SwapPrice */]: swapPriceFormatter,
  ["swap-trade-amount" /* SwapTradeAmount */]: swapTradeAmountFormatter,
  ["swap-details-amount" /* SwapDetailsAmount */]: swapDetailsAmountFormatter,
  ["fiat-token-quantity" /* FiatTokenQuantity */]: fiatTokenQuantityFormatter,
  ["fiat-token-details" /* FiatTokenDetails */]: fiatTokenDetailsFormatter,
  ["chart-fiat-value" /* ChartFiatValue */]: chartFiatValueFormatter,
  ["chart-volume-price-scale" /* ChartVolumePriceScale */]: chartVolumePriceScale,
  ["fiat-token-price" /* FiatTokenPrice */]: fiatTokenPricesFormatter,
  ["fiat-token-stats" /* FiatTokenStats */]: fiatTokenStatsFormatter,
  ["fiat-gas-price" /* FiatGasPrice */]: fiatGasPriceFormatter,
  ["portfolio-balance" /* PortfolioBalance */]: portfolioBalanceFormatter,
  ["nft-token-floor-price" /* NFTTokenFloorPrice */]: ntfTokenFloorPriceFormatter,
  ["nft-token-floor-price-trailing-zeros" /* NFTTokenFloorPriceTrailingZeros */]: ntfTokenFloorPriceFormatterTrailingZeros,
  ["nft-collection-stats" /* NFTCollectionStats */]: ntfCollectionStatsFormatter,
  ["nft-token" /* NFTToken */]: nftTokenFormatter,
  ["fiat-nft-token" /* FiatNFTToken */]: fiatNftTokenFormatter,
  ["whole-number" /* WholeNumber */]: wholeNumberFormatter
};
function getFormatterRule(input, type, conversionRate) {
  const rules = Array.isArray(type) ? type : TYPE_TO_FORMATTER_RULES[type];
  for (const rule of rules) {
    const shouldConvertInput = rule.formatterOptions.currency && conversionRate;
    const convertedInput = shouldConvertInput ? input * conversionRate : input;
    if (rule.exact !== void 0 && convertedInput === rule.exact || rule.upperBound !== void 0 && convertedInput < rule.upperBound) {
      return rule;
    }
  }
  throw new Error(`formatter for type ${type} not configured correctly for value ${input}`);
}
function formatNumber({
  input,
  type = "token-non-tx" /* TokenNonTx */,
  placeholder = "-",
  locale = DEFAULT_LOCALE,
  localCurrency = DEFAULT_LOCAL_CURRENCY,
  conversionRate
}) {
  if (input === null || input === void 0) {
    return placeholder;
  }
  const { hardCodedInput, formatterOptions } = getFormatterRule(input, type, conversionRate);
  if (formatterOptions.currency) {
    input = conversionRate ? input * conversionRate : input;
    formatterOptions.currency = localCurrency;
    formatterOptions.currencyDisplay = getCurrencySymbolDisplayType(localCurrency);
  }
  if (!hardCodedInput) {
    return new Intl.NumberFormat(locale, formatterOptions).format(input);
  }
  if (hardCodedInput.hardcodedOutput) {
    return hardCodedInput.hardcodedOutput;
  }
  const { input: hardCodedInputValue, prefix } = hardCodedInput;
  if (hardCodedInputValue === void 0) {
    return placeholder;
  }
  return (prefix ?? "") + new Intl.NumberFormat(locale, formatterOptions).format(hardCodedInputValue);
}
export function formatCurrencyAmount({
  amount,
  type = "token-non-tx" /* TokenNonTx */,
  placeholder,
  locale = DEFAULT_LOCALE,
  localCurrency = DEFAULT_LOCAL_CURRENCY,
  conversionRate
}) {
  return formatNumber({
    input: amount ? parseFloat(amount.toSignificant()) : void 0,
    type,
    placeholder,
    locale,
    localCurrency,
    conversionRate
  });
}
function formatPercent(percent, locale = DEFAULT_LOCALE) {
  if (!percent) {
    return "-";
  }
  return `${Number(percent.toFixed(3)).toLocaleString(locale, {
    maximumFractionDigits: 3,
    useGrouping: false
  })}%`;
}
function formatDelta(delta, locale = DEFAULT_LOCALE) {
  if (delta === null || delta === void 0 || delta === Infinity || isNaN(delta)) {
    return "-";
  }
  return `${Number(Math.abs(delta).toFixed(2)).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  })}%`;
}
function formatPrice({
  price,
  type = "fiat-token-price" /* FiatTokenPrice */,
  locale = DEFAULT_LOCALE,
  localCurrency = DEFAULT_LOCAL_CURRENCY,
  conversionRate
}) {
  if (price === null || price === void 0) {
    return "-";
  }
  return formatNumber({
    input: parseFloat(price.toSignificant()),
    type,
    locale,
    localCurrency,
    conversionRate
  });
}
function formatTickPrice({
  price,
  atLimit,
  direction,
  placeholder,
  numberType,
  locale,
  localCurrency,
  conversionRate
}) {
  if (atLimit[direction]) {
    return direction === Bound.LOWER ? "0" : "\u221E";
  }
  if (!price && placeholder !== void 0) {
    return placeholder;
  }
  return formatPrice({ price, type: numberType ?? "token-non-tx" /* TokenNonTx */, locale, localCurrency, conversionRate });
}
function formatNumberOrString({
  input,
  type,
  locale,
  localCurrency,
  conversionRate
}) {
  if (input === null || input === void 0) {
    return "-";
  }
  if (typeof input === "string") {
    return formatNumber({ input: parseFloat(input), type, locale, localCurrency, conversionRate });
  }
  return formatNumber({ input, type, locale, localCurrency, conversionRate });
}
function formatEther({ input, type, locale, localCurrency }) {
  if (input === null || input === void 0) {
    return "-";
  }
  return formatNumber({ input: parseFloat(ethersFormatEther(input.toString())), type, locale, localCurrency });
}
function formatFiatPrice({
  price,
  type = "fiat-token-price" /* FiatTokenPrice */,
  locale,
  localCurrency,
  conversionRate
}) {
  return formatNumberOrString({ input: price, type, locale, localCurrency, conversionRate });
}
const MAX_AMOUNT_STR_LENGTH = 9;
function formatReviewSwapCurrencyAmount(amount, locale = DEFAULT_LOCALE) {
  let formattedAmount = formatCurrencyAmount({ amount, type: "token-tx" /* TokenTx */, locale });
  if (formattedAmount.length > MAX_AMOUNT_STR_LENGTH) {
    formattedAmount = formatCurrencyAmount({ amount, type: "swap-trade-amount" /* SwapTradeAmount */, locale });
  }
  return formattedAmount;
}
export function getFiatCurrencyComponents(locale = DEFAULT_LOCALE, localCurrency = DEFAULT_LOCAL_CURRENCY) {
  const format = new Intl.NumberFormat(locale, {
    ...TWO_DECIMALS_CURRENCY,
    currency: localCurrency,
    currencyDisplay: getCurrencySymbolDisplayType(localCurrency)
  });
  const parts = format.formatToParts(1e6);
  let groupingSeparator = ",";
  let decimalSeparator = ".";
  let symbol = "";
  let fullSymbol = "";
  let symbolAtFront = true;
  parts.forEach((part, index) => {
    if (part.type === "group") {
      groupingSeparator = part.value;
    } else if (part.type === "decimal") {
      decimalSeparator = part.value;
    } else if (part.type === "currency") {
      symbol = part.value;
      fullSymbol = symbol;
      symbolAtFront = index === 0;
      const nextPart = symbolAtFront ? parts[index + 1] : parts[index - 1];
      if (nextPart?.type === "literal") {
        fullSymbol = symbolAtFront ? symbol + nextPart.value : nextPart.value + symbol;
      }
    }
  });
  return {
    groupingSeparator,
    decimalSeparator,
    symbol,
    fullSymbol,
    symbolAtFront
  };
}
export function useFormatterLocales() {
  const activeLocale = useActiveLocale();
  const activeLocalCurrency = useActiveLocalCurrency();
  return {
    formatterLocale: activeLocale,
    formatterLocalCurrency: activeLocalCurrency
  };
}
function handleFallbackCurrency(selectedCurrency, previousSelectedCurrency, previousConversionRate, shouldFallbackToUSD, shouldFallbackToPrevious) {
  if (shouldFallbackToUSD) {
    return DEFAULT_LOCAL_CURRENCY;
  }
  if (shouldFallbackToPrevious) {
    return previousConversionRate ? previousSelectedCurrency : DEFAULT_LOCAL_CURRENCY;
  }
  return selectedCurrency;
}
export function useFormatter() {
  const { formatterLocale, formatterLocalCurrency } = useFormatterLocales();
  const { convertFiatAmount, conversionRate: localCurrencyConversionRate } = useLocalizationContext();
  const previousSelectedCurrency = usePrevious(formatterLocalCurrency);
  const previousConversionRate = usePrevious(localCurrencyConversionRate);
  const shouldFallbackToPrevious = !localCurrencyConversionRate;
  const shouldFallbackToUSD = !localCurrencyConversionRate;
  const currencyToFormatWith = handleFallbackCurrency(
    formatterLocalCurrency,
    previousSelectedCurrency,
    previousConversionRate,
    shouldFallbackToUSD,
    shouldFallbackToPrevious
  );
  const localCurrencyConversionRateToFormatWith = shouldFallbackToPrevious ? previousConversionRate : localCurrencyConversionRate;
  const formatNumberWithLocales = useCallback(
    (options) => formatNumber({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatCurrencyAmountWithLocales = useCallback(
    (options) => formatCurrencyAmount({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatPriceWithLocales = useCallback(
    (options) => formatPrice({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatReviewSwapCurrencyAmountWithLocales = useCallback(
    (amount) => formatReviewSwapCurrencyAmount(amount, formatterLocale),
    [formatterLocale]
  );
  const formatTickPriceWithLocales = useCallback(
    (options) => formatTickPrice({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatNumberOrStringWithLocales = useCallback(
    (options) => formatNumberOrString({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatFiatPriceWithLocales = useCallback(
    (options) => formatFiatPrice({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: localCurrencyConversionRateToFormatWith
    }),
    [currencyToFormatWith, formatterLocale, localCurrencyConversionRateToFormatWith]
  );
  const formatDeltaWithLocales = useCallback(
    (percent) => formatDelta(percent, formatterLocale),
    [formatterLocale]
  );
  const formatPercentWithLocales = useCallback(
    (percent) => formatPercent(percent, formatterLocale),
    [formatterLocale]
  );
  const formatEtherwithLocales = useCallback(
    (options) => formatEther({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith
    }),
    [currencyToFormatWith, formatterLocale]
  );
  const formatConvertedFiatNumberOrString = useCallback(
    (options) => formatNumberOrString({
      ...options,
      locale: formatterLocale,
      localCurrency: currencyToFormatWith,
      conversionRate: void 0
    }),
    [currencyToFormatWith, formatterLocale]
  );
  return useMemo(
    () => ({
      convertToFiatAmount: convertFiatAmount,
      formatConvertedFiatNumberOrString,
      formatCurrencyAmount: formatCurrencyAmountWithLocales,
      formatEther: formatEtherwithLocales,
      formatFiatPrice: formatFiatPriceWithLocales,
      formatNumber: formatNumberWithLocales,
      formatNumberOrString: formatNumberOrStringWithLocales,
      formatDelta: formatDeltaWithLocales,
      formatPercent: formatPercentWithLocales,
      formatPrice: formatPriceWithLocales,
      formatReviewSwapCurrencyAmount: formatReviewSwapCurrencyAmountWithLocales,
      formatTickPrice: formatTickPriceWithLocales
    }),
    [
      convertFiatAmount,
      formatConvertedFiatNumberOrString,
      formatCurrencyAmountWithLocales,
      formatDeltaWithLocales,
      formatEtherwithLocales,
      formatFiatPriceWithLocales,
      formatNumberOrStringWithLocales,
      formatNumberWithLocales,
      formatPercentWithLocales,
      formatPriceWithLocales,
      formatReviewSwapCurrencyAmountWithLocales,
      formatTickPriceWithLocales
    ]
  );
}
